<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-select-remote-search label="电费项目" multiple reserveKeyword v-model="pageParam.params.projectIdList" :options="optionsElectric" placeholder="请选择电费项目(可搜索)" />
                <le-select-remote-search label="电表号" multiple reserveKeyword v-model="pageParam.params.ammeterIdList" :options="optionsAmmeter" placeholder="请选择电表(可搜索)" />
                <le-select-remote-search label="站点" multiple reserveKeyword v-model="pageParam.params.stationIdList" :options="optionsStation" placeholder="请选择站点(可搜索)" />
                <le-date-range label="抄表时间" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" />
            </le-search-form>
            <le-pagview 
                ref="electricBillListPage" 
                @setData="setTableData" 
                :pageParam="pageParam" 
                :isFixed="true" 
                :tableRef="$refs.electricBillList" 
                :pageSizeArr='[1]'
                :pageSizeDefault='1'>
                <el-table 
                    ref="electricBillList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    :span-method='mergeCols'
                    :border='true'
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="projectId" label="电费项目" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.projectName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationId" label="站点名称" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ammeterId" label="电表号" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.ammeterNo || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceNumId" label="设备总数/插槽总数" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceNumByRadio || 0 }}/{{ row.slotNumByRadio || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastRecordTimeId" label="上次抄表时间" min-width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.lastRecordTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastRecordPowerId" label="上次度数（kwh）" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.lastRecordPower || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="recordTimeId" label="本次抄表时间" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="recordPowerId" label="本次度数（kwh）" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordPower || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="intervalPowerId" label="应结算度数（kwh）" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.intervalPower || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="unitPriceId" label="单价（/元/kwh）" min-width="140">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.unitPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="needPayAmountId" label="应付电费" min-width="140">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.needPayAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalPayId" label="应付合计" min-width="140">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalPay) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="intervalIncomeId" label="区间收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.intervalIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalProfitId" label="收益合计" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.totalProfit) }}</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="lastRemainMoneyId" label="上期剩余费用" min-width="140" v-if="chargeFeeType == 1">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.lastRemainMoney) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="prePayAmountId" label="预付费用" min-width="140" v-if="chargeFeeType == 1">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.prePayAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="intervalRatioId" label="区间电费占比" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.intervalRatio) }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalRatioId" label="项目总电费占比" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormat(row.ratio) }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="finalRemainMoneyId" label="剩余费用预估" min-width="120" v-if="chargeFeeType == 1">
                        <template slot-scope="{ row }">
                            <span :class="finalRemainMoneyClass(row)">￥{{ util.numFormat(row.finalRemainMoney) }}{{ row.endDate?'（截止日期' + row.endDate + '）' : '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="80" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <img src="../../assets/icon/option-refuse.png" class="a-wh-16" @click="electricBillDelete(scope.row)" v-if="scope.row.isNew" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            <div style="height: 16px"></div>

                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-cfsfs">
                        <div class="a-flex-rfsc">
                            <div class="a-flex-rfsc" style="margin-right: 40px">
                                <span class="a-fs-12 a-c-normal">本期应付金额：</span>
                                <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(totalPay)}}</span>
                            </div>
                            <div class="a-flex-rfsc">
                                <span class="a-fs-12 a-c-normal">本期应付度数：</span>
                                <span class="a-fs-16 a-c-master a-fw-b">{{totalEnergy || 0}}kwh</span>
                            </div>
                        </div>
                        <div class="a-flex-rfsc">
                            <div class="a-flex-rfsc" style="margin-right: 40px">
                                <span class="a-fs-12 a-c-normal">搜索时间内应付金额：</span>
                                <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.intervalPay)}}</span>
                            </div>
                            <div class="a-flex-rfsc">
                                <span class="a-fs-12 a-c-normal">搜索时间内应付度数：</span>
                                <span class="a-fs-16 a-c-master a-fw-b">{{scope.val.intervalPower || 0}}kwh</span>
                            </div>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.electricBillRecordBill,
                    method: "post",
                    params: {
                        startDate: '',
                        endDate: '',
                        projectIdList: [Number(this.$route.query.projectId)],
                        ammeterIdList: [],
                        stationIdList: []
                    },
                    freshCtrl: 1,
                },
                optionsStation: {
                    url: this.$Config.apiUrl.getStationList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "stationId",
                    searchKey: "name",
                },//站点数据
                optionsElectric: {
                    url: this.$Config.apiUrl.electricFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },
                optionsAmmeter: {
                    url: this.$Config.apiUrl.ammeterFilter,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },
                chargeFeeType: '',
                totalEnergy: '',//本期应付总电量
                totalPay: '',//本期应付总金额
            }
        },
        computed: {
            // 获取所有单元格合并数据
            spanArr() {
                if(!this.tableData.length) return []
                let mergeCols = [] // 需要合并的列（字段）
                if(this.chargeFeeType == 1) {
                    mergeCols = ['projectId',
                        'stationId',
                        'ammeterId',
                        'deviceNumId',
                        'lastRecordTimeId',
                        'lastRecordPowerId',
                        'recordTimeId',
                        'recordPowerId',
                        'intervalPowerId',
                        'unitPriceId',
                        'needPayAmountId',
                        'totalPayId',
                        'intervalIncomeId',
                        'totalProfitId',
                        'lastRemainMoneyId',
                        'prePayAmountId',
                        'intervalRatioId',
                        'totalRatioId',
                        'finalRemainMoneyId',
                        'pid',
                    ] // 需要合并的列（字段）
                }else if(this.chargeFeeType == 2) {
                    mergeCols = ['projectId',
                        'stationId',
                        'ammeterId',
                        'deviceNumId',
                        'lastRecordTimeId',
                        'lastRecordPowerId',
                        'recordTimeId',
                        'recordPowerId',
                        'intervalPowerId',
                        'unitPriceId',
                        'needPayAmountId',
                        'totalPayId',
                        'intervalIncomeId',
                        'totalProfitId',
                        'intervalRatioId',
                        'totalRatioId',
                        'pid',
                    ] // 需要合并的列（字段）
                }
                return util.getMergeCells(this.tableData, mergeCols, mergeCols)
            }
        },
        created () {
            this.chargeFeeType = this.$route.query.chargeFeeType
        },
        methods:{
            //获取列表
            setTableData(datas) {
                
                let arr = [], totalPay = 0, totalEnergy = 0
                // let resData = datas[0]
                datas.map(resData=>{
                    resData.billAmmeterRecordResultList.map(item=>{
                        let tag = '-' + JSON.stringify(item.billId) //每一个项目标识符
                        let obj = {
                            ...item,
                            // currentFee: resData.currentFee,
                            intervalRatio: item.intervalRatio,
                            projectId: resData.projectId,
                            projectName: resData.projectName,
                            ratio: resData.ratio,
                            totalEnergy: resData.totalEnergy,
                            totalPay: resData.totalPay,
                            totalProfit: resData.totalProfit,
                            unitPrice: resData.unitPrice,
                            isNew: resData.isNew,

                            ammeterId: item.ammeterId + tag,
                            projectId: item.projectId + tag,
                            stationId: item.stationId + tag,
                            deviceNumId: item.ammeterId + tag,
                            recordTimeId: item.projectId + tag,
                            recordPowerId: item.ammeterId + tag,
                            remainMoneyId: item.ammeterId + tag,
                            prePayAmountId: item.ammeterId + tag,
                            needPayAmountId: item.ammeterId + tag,
                            lastRecordTimeId: item.projectId + tag,
                            lastRecordPowerId: item.ammeterId + tag,
                            unitPriceId: resData.projectId + tag,
                            intervalIncomeId: item.stationId + tag,
                            totalProfitId: resData.projectId + tag,
                            totalPayId: resData.projectId + tag,
                            lastRemainMoneyId:  item.ammeterId + tag,
                            intervalRatioId: item.ammeterId + tag,
                            ratioId: resData.projectId + tag,
                            totalRatioId: resData.projectId + tag,
                            finalRemainMoneyId: item.ammeterId + tag,
                            intervalPowerId: item.ammeterId + tag,
                            pid: resData.projectId,
                        }
                        arr.push(obj)
                    })
                    totalPay = totalPay += (Number(resData.totalPay) || 0)
                    totalEnergy = totalEnergy += (Number(resData.totalEnergy) || 0)
                })
                this.totalPay = totalPay 
                this.totalEnergy = totalEnergy 
                this.tableData = arr
            },
            handlerRest() {
                this.pageParam.params = {
                    startDate: '',
                    endDate: '',
                    projectIdList: [],
                    ammeterIdList: [],
                    stationIdList: []
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['electricBillList'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                return this.spanArr[rowIndex][columnIndex]
            },
            finalRemainMoneyClass (row) {
                let value = row.finalRemainMoney || 0
                if(value < 2000) {
                    return 'alertInfoError'
                }else if(value < 5000) {
                    return 'alertInfoWarning'
                }else {
                    return ''
                }
            },
            electricBillDelete (datas) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.electricBillDelete,
                        method: "post",
                        params: {
                            billId: datas.billId
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                })
                .catch(_=>{})
            },
            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92)
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0)
                this.$exfile({
                    code: 18,
                    fileName: startDate + ' 至 ' + endDate + ' 电费项目账单维度统计',
                    startTime: startDate,
                    endTime: endDate,
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 135px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.alertInfoWarning{
    color: #E6A23C;
}
.alertInfoError{
    color: #F56C6C;
}
</style>